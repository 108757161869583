import { IconApi, IconBuildingStore, IconLock, IconSettings, IconUsers } from "@tabler/icons-react";
import { RemoveRequestDTO } from "core/lib/payment-putty/gift-cards/dto/RemoveCartDTO";
import {
  ApplyTransactionDTO,
  CheckBalanceTransactionDTO,
  CommitTransactionDTO,
  VerifyTransactionDTO,
} from "core/lib/payment-putty/gift-cards/dto/TransactionDTO";

export const APP_MODE = {
  base: "base",
  sprokit: "sprokit",
};

export const CONFIG = {
  ALLOW_CUSTOM_AUTH: false,
};

export const PERMISSIONS = {
  storePickup: [
    {
      id: "reschedule",
      name: "Reschedule Pickup",
    },
    {
      id: "printPackingSlip",
      name: "Print Packing Slip",
    },
    {
      id: "managePickupWindows",
      name: "Manage Pickup Windows",
    },
    {
      id: "editPickupSettings",
      name: "Edit Pickup Settings",
    },
    {
      id: "editPickupLocations",
      name: "Edit Pickup Locations",
    },
  ],
  paymentPutty: [
    {
      id: "refunds",
      name: "Refunds",
    },
  ],
};

export const APP_EXTENSIONS = {
  // url in app extensions = /{scope}/{id}
  REDIRECTS: {
    order: "/refunds",
    pickup: "/store-pickup",
    product: "",
    customer: "",
  },
};

export const ADMIN_TABS = [
  { href: "/admin/stores", text: "Stores", icon: <IconBuildingStore /> },
  { href: "/admin/secrets", text: "Secrets", icon: <IconLock /> },
  { href: "/admin/endpoints", text: "Flex Endpoints", icon: <IconApi /> },
  { href: "/admin/api", text: "API", icon: <IconApi /> },
  { href: "/admin/users", text: "Users", icon: <IconUsers /> },
  // { href: "/admin/store-logs", text: "Store Logs"  },
  { href: "/admin/settings", text: "Settings", icon: <IconSettings /> },
  { href: "/admin/scripts", text: "Script Manager", icon: <IconSettings /> },
  { href: "/admin/integrations", text: "Integrations", icon: <IconSettings /> },
  // { href: "/admin/logs", text: "Server Logs", icon: <IconListDetails /> },
];

export const BIGCOMMERCE = {
  CHANNELS: {},
  CUSTOM_FIELDS: {},
  PAYMENT_METHODS: {
    moneyorder: "Credit Card",
    cod: "Purchase Order",
  },
};

export const PLATFORM = {
  BIGCOMMERCE: {
    name: "BigCommerce",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/bigcommerce-icon.png",
    url: "https://www.bigcommerce.com/",
  },
  INTEGRATION_PLATFORM: {
    name: "Integration Platform",
    url: "https://www.bigcommerce.com/",
  },
  RYZOME: {
    name: "Ryzome",
    url: "https://www.bigcommerce.com/",
  },
  ABILITY_COMMERCE: {
    name: "Ability Commerce",
    logo: "https://www.abilitycommerce.com/wp-content/uploads/2018/10/AC_Favicon.jpg",
    url: "https://www.bigcommerce.com/",
  },
  AUTHORIZE_NET: {
    name: "Authorize.NET",
    logo: "https://www.authorize.net/content/dam/anet2021/images/evergreen/favicon-64x64.png",
    url: "https://www.bigcommerce.com/",
  },
  ACOUSTIC: {
    name: "Acoustic",
    logo: "https://www.acoustic.com/favicon.ico",
    url: "https://www.acoustic.com",
  },
  BAMBORA: {
    name: "Bambora",
    tooltip: "TD Online Mart by Bambora",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/TdMerchant.jpg",
    url: "https://dev.na.bambora.com/docs/",
  },
  PAYMENT_PUTTY: {
    name: "PaymentPutty",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/PaymentPutty.png",
    logoUrl: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/PaymentPuttyTagline.png",
  },
  AWS_S3: {
    name: "AWS S3",
    logo: "https://signin.aws.amazon.com/favicon.ico",
    url: "https://s3.console.aws.amazon.com",
  },
  SHIPPERHQ: {
    name: "ShipperHQ",
    logo: "https://cdn.shipperhq.com/assets/favicon-32x32-84b766cd9d2b380b0b12fedd25a44b403cc9af5525ff1fd228f17d69aa7a708c.png",
    url: "https://shipperhq.com/",
  },
};

export const EMAIL_TEMPLATES = [
  "core/email/email-notification.jsx",
  "core/email/email-wrap.jsx",
  "core/email/reset-password.jsx",
  "core/email/store-monitor.jsx",
  "core/email/task-overlap-protection.jsx",
];

export const STORE_MONITOR_FREQUENCY = [
  { value: "everyday", text: "Everyday" },
  { value: "weekday", text: "Monday - Friday" },
  { value: "weekend", text: "Saturday & Sunday" },
  { value: "monday", text: "Monday" },
  { value: "tuesday", text: "Tuesday" },
  { value: "wednesday", text: "Wednesday" },
  { value: "thursday", text: "Thursday" },
  { value: "friday", text: "Friday" },
  { value: "saturday", text: "Saturday" },
  { value: "sunday", text: "Sunday" },
];

export const TRACKING_INFO = {
  MEDIUM: [
    "Article",
    "Banner-Ad",
    "CPC",
    "Direct-Mail",
    "Email",
    "Giveaway",
    "Literature",
    "Newsletter",
    "Outdoor",
    "Press-Release",
    "Print-Advertising",
    "Radio",
    "Social-Marketing",
    "Television",
    "Video",
    "Web-Advertising",
    "White-Paper",
  ],
  SOURCE: [
    "ABC",
    "Adams-Outdoor",
    "Bing-MSN",
    "Business-Wire",
    "CBS",
    "Center-Daily-Times",
    "CPB-Journal",
    "Daily-Item",
    "ESPN",
    "Facebook",
    "Fox",
    "Google",
    "Hoovers-List",
    "InfoUSA-List",
    "Lamar-Outdoor",
    "LinkedIn",
    "LVB-Journal",
    "NBC",
    "NEB-Journal",
    "Patriot-News",
    "Press-Enterprise",
    "Sentinel",
    "Standard-Journal",
    "Sun-Gazette",
    "Twitter",
    "USA-Today",
    "Vocus",
    "Yahoo",
    "YouTube",
  ],
};

export const CONSTANTS = {
  CONFIG,
  BIGCOMMERCE,
  PLATFORM,
  TRACKING_INFO,
};

export const BIGCOMMERCE_WEBHOOK_EVENTS = [
  "store/brand/metafield/*",
  "store/brand/metafield/created",
  "store/brand/metafield/deleted",
  "store/brand/metafield/updated",
  "store/cart/*",
  "store/cart/abandoned",
  "store/cart/converted",
  "store/cart/couponApplied",
  "store/cart/created",
  "store/cart/deleted",
  "store/cart/lineItem/*",
  "store/cart/lineItem/created",
  "store/cart/lineItem/deleted",
  "store/cart/lineItem/updated",
  "store/cart/metafield/created",
  "store/cart/metafield/deleted",
  "store/cart/metafield/updated",
  "store/cart/updated",
  "store/category/*",
  "store/category/created",
  "store/category/deleted",
  "store/category/metafield/created",
  "store/category/metafield/deleted",
  "store/category/metafield/updated",
  "store/category/updated",
  "store/customer/*",
  "store/customer/address/*",
  "store/customer/address/created",
  "store/customer/address/deleted",
  "store/customer/address/updated",
  "store/customer/created",
  "store/customer/deleted",
  "store/customer/payment/instrument/default/updated",
  "store/customer/updated",
  "store/information/updated",
  "store/inventory/location/metafield/*",
  "store/inventory/location/metafield/created",
  "store/inventory/location/metafield/deleted",
  "store/inventory/location/metafield/updated",
  "store/metafield/*",
  "store/metafield/created",
  "store/metafield/deleted",
  "store/metafield/updated",
  "store/modifier/updated",
  "store/option/updated",
  "store/order/*",
  "store/order/archived",
  "store/order/created",
  "store/order/message/created",
  "store/order/metafield/created",
  "store/order/metafield/deleted",
  "store/order/metafield/updated",
  "store/order/refund/created",
  "store/order/statusUpdated",
  "store/order/transaction/created",
  "store/order/transaction/updated",
  "store/order/updated",
  "store/priceList/activated",
  "store/priceList/assignment/deleted",
  "store/priceList/assignment/updated",
  "store/priceList/created",
  "store/priceList/deactivated",
  "store/priceList/deleted",
  "store/priceList/record/created",
  "store/priceList/record/deleted",
  "store/priceList/record/updated",
  "store/priceList/updated",
  "store/product/*",
  "store/product/created",
  "store/product/deleted",
  "store/product/inventory/order/updated",
  "store/product/inventory/updated",
  "store/product/metafield/*",
  "store/product/metafield/created",
  "store/product/metafield/deleted",
  "store/product/metafield/updated",
  "store/product/updated",
  "store/product/variant/metafield/*",
  "store/product/variant/metafield/created",
  "store/product/variant/metafield/deleted",
  "store/product/variant/metafield/updated",
  "store/shipment/*",
  "store/shipment/created",
  "store/shipment/deleted",
  "store/shipment/updated",
  "store/sku/*",
  "store/sku/created",
  "store/sku/deleted",
  "store/sku/inventory/order/updated",
  "store/sku/inventory/updated",
  "store/sku/updated",
  "store/subscriber/*",
  "store/subscriber/created",
  "store/subscriber/deleted",
  "store/subscriber/updated",
  "store/app/uninstalled",
];

export const BIGCOMMERCE_CHANNEL_WEBHOOK_EVENTS = [
  "store/channel/{channel_id}/cart/*",
  "store/channel/{channel_id}/cart/created",
  "store/channel/{channel_id}/cart/updated",
  "store/channel/{channel_id}/cart/deleted",
  "store/channel/{channel_id}/cart/abandoned",
  "store/channel/{channel_id}/cart/converted",
  "store/channel/{channel_id}/cart/couponApplied",
  "store/channel/{channel_id}/cart/lineItem/*",
  "store/channel/{channel_id}/cart/lineItem/created",
  "store/channel/{channel_id}/cart/lineItem/updated",
  "store/channel/{channel_id}/cart/lineItem/deleted",
  "store/channel/{channel_id}/category/*",
  "store/channel/{channel_id}/category/created",
  "store/channel/{channel_id}/category/updated",
  "store/channel/{channel_id}/category/deleted",
  "store/channel/{channel_id}/categoryTree/updated",
  "store/customer/channel/login/access/updated",
  "store/channel/{channel_id}/settings/emailStatus/updated",
  "store/channel/{channel_id}/settings/emailStatus/deleted",
  "store/channel/{channel_id}/email/templates/updated",
  "store/channel/{channel_id}/email/templates/deleted",
  "store/channel/{channel_id}/notifications/abandonedCart/updated",
  "store/channel/{channel_id}/notifications/inventory/updated",
  "store/channel/{channel_id}/order/*",
  "store/channel/{channel_id}/order/created",
  "store/channel/{channel_id}/order/updated",
  "store/channel/{channel_id}/order/archived",
  "store/channel/{channel_id}/order/statusUpdated",
  "store/channel/{channel_id}/order/message/created",
  "store/channel/{channel_id}/order/refund/created",
  "store/channel/{channel_id}/page/created",
  "store/channel/{channel_id}/page/updated",
  "store/channel/{channel_id}/product/assigned",
  "store/channel/{channel_id}/product/unassigned",
  "store/channel/{channel_id}/category/product/assigned",
  "store/channel/{channel_id}/category/product/unassigned",
  "store/channel/{channel_id}/settings/route/updated",
  "store/channel/{channel_id}/script/created",
  "store/channel/{channel_id}/script/updated",
  "store/channel/{channel_id}/settings/*",
  "store/channel/{channel_id}/settings/currency/updated",
  "store/channel/{channel_id}/settings/profile/updated",
  "store/channel/{channel_id}/settings/logo/updated",
  "store/channel/{channel_id}/settings/logo/image/updated",
  "store/channel/{channel_id}/settings/favicon/image/updated",
  "store/channel/{channel_id}/settings/checkout/updated",
  "store/channel/{channel_id}/settings/SEO/updated",
  "store/channel/{channel_id}/settings/robots/updated",
  "store/channel/{channel_id}/settings/category/updated",
  "store/channel/{channel_id}/settings/product/updated",
  "store/channel/{channel_id}/settings/catalog/updated",
  "store/channel/{channel_id}/settings/security/updated",
  "store/channel/{channel_id}/settings/searchContextFilters/updated",
  "store/channel/{channel_id}/settings/defaultCustomerGroup/updated",
  "store/channel/{channel_id}/settings/customerPrivacy/updated",
  "store/channel/{channel_id}/settings/customerPrivacy/updated",
  "store/channel/{channel_id}/settings/sites/updated",
  "store/channel/{channel_id}/settings/sites/checkoutUrl/updated",
  "store/channel/{channel_id}/settings/sites/checkoutUrl/deleted",
  "store/channel/{channel_id}/socialMediaLinks/updated",
  "store/channel/{channel_id}/theme/configuration/created",
  "store/channel/{channel_id}/theme/configuration/activated",
];

export const BIGCOMMERCE_WEBHOOK_DESCRIPTIONS = [
  { scope: "store/brand/metafield/*", desc: "Fires for all store/brand/metafield events." },
  { scope: "store/brand/metafield/created", desc: "Fires when a new brand metafield is created." },
  { scope: "store/brand/metafield/updated", desc: "Fires when a brand metafield is modified." },
  { scope: "store/brand/metafield/deleted", desc: "Fires when a brand metafield is deleted." },
  { scope: "store/order/*", desc: "Subscribe to all store/order events" },
  { scope: "store/order/created", desc: "Fires if an order is created using the control panel, an app or via the API." },
  {
    scope: "store/order/updated",
    desc: "Fires when an already created order is updated. Any changes to an existing order will fire this webhook. Updates can include changing the status, updating a coupon or changing an address.",
  },
  { scope: "store/order/archived", desc: "Order is archived" },
  { scope: "store/order/statusUpdated", desc: "This will only fire if the order status has changed. Such as Pending to Awaiting Payment" },
  { scope: "store/order/message/created", desc: "Order message is created by customer or in control panel" },
  { scope: "store/order/refund/created", desc: "A refund has been submitted against an order" },
  { scope: "store/order/transaction/created", desc: "Fires when a transaction is created." },
  { scope: "store/order/transaction/updated", desc: "Fires when a transaction is updated." },
  { scope: "store/order/metafield/*", desc: "Fires for all store/order/metafield events." },
  { scope: "store/order/metafield/created", desc: "Fires if an order metafield is created using the control panel, an app, or the API." },
  {
    scope: "store/order/metafield/updated",
    desc: "Fires when an existing order metafield is updated. Any change to an existing order metafield fires this webhook.",
  },
  { scope: "store/order/metafield/deleted", desc: "Fires when an order metafield is deleted." },
  { scope: "store/product/*", desc: "Subscribe to all store/product events" },
  { scope: "store/product/deleted", desc: "Product is deleted" },
  { scope: "store/product/created", desc: "A new product is created" },
  {
    scope: "store/product/updated",
    desc: "Occurs when product details are edited. For a full list of product fields that trigger an updated event, see Product updated events below",
  },
  { scope: "store/product/inventory/updated", desc: "Product inventory is updated." },
  {
    scope: "store/product/inventory/order/updated",
    desc: "Fires if a product's inventory is decremented or incremented, including when an order is placed. Webhook responds to inventory updates made using the control panel, CSV import, API or an app.",
  },
  { scope: "store/product/metafield/*", desc: "Fires for all store/product/metafield events." },
  { scope: "store/product/metafield/created", desc: "Fires if a product metafield is created." },
  {
    scope: "store/product/metafield/updated",
    desc: "Fires when an existing product metafield is updated.",
  },
  { scope: "store/product/metafield/deleted", desc: "Fires when a product metafield is deleted." },
  { scope: "store/product/variant/metafield/*", desc: "Fires for all store/product/variant/metafield events." },
  { scope: "store/category/*", desc: "Subscribe to all store/category events" },
  { scope: "store/category/created", desc: "Category is created" },
  { scope: "store/category/updated", desc: "Category is updated" },
  { scope: "store/category/deleted", desc: "Category is deleted" },
  { scope: "store/category/metafield/*", desc: "Fires for all store/category/metafield events." },
  { scope: "store/category/metafield/created", desc: "Fires if a category metafield is created." },
  { scope: "store/category/metafield/updated", desc: "Fires when an existing category metafield is updated." },
  { scope: "store/category/metafield/deleted", desc: "Fires when a category metafield is deleted." },
  { scope: "store/sku/*", desc: "Subscribe to all store/sku events" },
  { scope: "store/sku/created", desc: "A new sku is created" },
  { scope: "store/sku/updated", desc: "SKU is updated" },
  { scope: "store/sku/deleted", desc: "SKU is deleted" },
  { scope: "store/sku/inventory/updated", desc: "SKU is updated" },
  {
    scope: "store/sku/inventory/order/updated",
    desc: "This will fire when the inventory is updated via API, the control panel, when an order is placed and when an order is refunded and the inventory is returned to stock. This hook will fire based on a store's Inventory settings.",
  },
  { scope: "store/customer/*", desc: "Subscribe to all store/customer events" },
  { scope: "store/customer/created", desc: "A new customer is created" },
  { scope: "store/customer/updated", desc: "Customer is updated. Does not currently track changes to the customer address." },
  { scope: "store/customer/deleted", desc: "Customer is deleted" },
  { scope: "store/customer/address/*", desc: "Fires for all store/customer/address events." },
  { scope: "store/customer/address/created", desc: "Customer address is created" },
  { scope: "store/customer/address/updated", desc: "Customer address is updated" },
  { scope: "store/customer/address/deleted", desc: "Customer address is deleted" },
  { scope: "store/customer/payment/instrument/default/updated", desc: "Customer default payment instrument is updated" },
  { scope: "store/app/uninstalled", desc: "Occurs when a client store is cancelled and uninstalled from the platform" },
  {
    scope: "store/information/updated",
    desc: "Occurs when changes are made to store settings. For a full list of fields that can trigger this event, see Store information updated events below",
  },
  { scope: "store/cart/*", desc: "Subscribe to all cart events. This will also subscribe you to cart/lineItem." },
  {
    scope: "store/cart/created",
    desc: "This webhook will fire whenever a new cart is created, either via a storefront shopper adding their first item to the cart, or when a new cart is created via an API consumer. If it is from the storefront, then it fires when the first product is added to a new session.(The cart did not exist before). For the API it means a POST to /carts, (V3 and Storefront API). The store/cart/updated hook will also fire.",
  },
  {
    scope: "store/cart/updated",
    desc: "This webhook is fired whenever a cart is modified through the changes in its line items. Eg. when a new item is added to a cart or an existing item's quantity is updated. This hook also fires when the email is changed during guest checkout or when an existing item is deleted. The payload will include the ID of the cart being updated. This webhook also fires along with the cart created hook, because the first product being added to an empty cart triggers an update. It will also fire for the following events:\n- Logging into customer account after creating a cart (email is inherited from customer account email)\n- Entering email address via guest checkout\n-Changing the email in guest checkout",
  },
  {
    scope: "store/cart/deleted",
    desc: "This webhook will fire whenever a cart is deleted. This will occur either when all items have been removed from a cart and it is auto-deleted, or when the cart is explicitly removed via a DELETE request by an API consumer. This ends the lifecycle of the cart. The store/cart/updated webhook will also fire when the last item is removed.",
  },
  {
    scope: "store/cart/couponApplied",
    desc: "This webhook will fire whenever a new coupon code is applied to a cart. It will include the ID of the coupon code",
  },
  {
    scope: "store/cart/abandoned",
    desc: "This webhook will fire once after a cart is abandoned. A cart is considered abandoned if no changes have been made for at least one hour after the last modified property. This hook is available for all store plans, regardless of whether the Abandoned Cart Saver feature is enabled.",
  },
  {
    scope: "store/cart/converted",
    desc: "This hook fires when a cart is converted into an order, which is typically after the payment step of checkout on the storefront. At this point, the cart is no longer accessible and has been deleted. This hook returns both the cart ID and order ID for correlation purposes.",
  },
  { scope: "store/cart/metafield/*", desc: "Fires for all store/cart/metafield events." },
  { scope: "store/cart/metafield/created", desc: "Fires if a cart metafield is created." },
  { scope: "store/cart/metafield/updated", desc: "Fires when an existing cart metafield is updated." },
  { scope: "store/cart/metafield/deleted", desc: "Fires when a cart metafield is deleted." },
  {
    scope: "store/cart/lineItem/*",
    desc: "Subscribe to all cart line item events. This webhook will fire when a change occurs to line items in the cart. This can be when items are added to a cart, removed or updated.(Ex. change to quantity, product options or price).",
  },
  { scope: "store/cart/lineItem/created", desc: "When a new item is added to the cart" },
  { scope: "store/cart/lineItem/updated", desc: "When an item's quantity has changed or the product options change." },
  { scope: "store/cart/lineItem/deleted", desc: "When an item is deleted from the cart" },
  { scope: "store/shipment/*", desc: "Subscribe to all store/shipment events" },
  { scope: "store/shipment/created", desc: "Shipment is created" },
  { scope: "store/shipment/updated", desc: "Shipment is updated" },
  { scope: "store/shipment/deleted", desc: "Shipment is deleted" },
  { scope: "store/inventory/location/metafield/*", desc: "Fires for all store/inventory/location/metafield events." },
  { scope: "store/inventory/location/metafield/created", desc: "Fires if an inventory location metafield is created." },
  {
    scope: "store/inventory/location/metafield/updated",
    desc: "Fires when an already created inventory location metafield is updated. Any change to an existing inventory location metafield fires this webhook.",
  },
  { scope: "store/inventory/location/metafield/deleted", desc: "Fires when an inventory location metafield is deleted." },
  { scope: "store/subscriber/*", desc: "Subscribe to all store/subscriber events" },
  { scope: "store/subscriber/created", desc: "Subscriber is created" },
  { scope: "store/subscriber/updated", desc: "Subscriber is updated" },
  { scope: "store/subscriber/deleted", desc: "Subscriber is deleted" },
  { scope: "store/priceList/created", desc: "Occurs when a price list is created." },
  { scope: "store/priceList/updated", desc: "Occurs when a price list is updated." },
  { scope: "store/priceList/activated", desc: "Occurs when a price list is activated." },
  { scope: "store/priceList/deactivated", desc: "Occurs when a price list is deactivated." },
  { scope: "store/priceList/deleted", desc: "Occurs when a price list is deleted." },
  { scope: "store/priceList/record/created", desc: "Occurs when a price list record is created." },
  { scope: "store/priceList/record/updated", desc: "Occurs when a price list record is updated." },
  { scope: "store/priceList/record/deleted", desc: "Occurs when a price list record is deleted." },
  { scope: "store/priceList/assignment/updated", desc: "Occurs when a price list assignment is updated." },
  { scope: "store/priceList/assignment/deleted", desc: "Occurs when a price list assignment is deleted." },
  { scope: "store/product/variant/metafield/created", desc: "Fires when a product variant metafield is created." },
  { scope: "store/product/variant/metafield/updated", desc: "Fires when a product variant metafield is updated." },
  { scope: "store/product/variant/metafield/deleted", desc: "Fires when a product variant metafield is deleted." },
  { scope: "store/modifier/updated", desc: "Fires when a product modifier is updated." },
  { scope: "store/option/updated", desc: "Fires when a product option is updated." },
  { scope: "store/channel/{channel_id}/cart/*", desc: "Subscribe to all channel cart events" },
  { scope: "store/channel/{channel_id}/cart/created", desc: "Fires when a new cart is created in a channel" },
  { scope: "store/channel/{channel_id}/cart/updated", desc: "Fires when a cart is updated in a channel" },
  { scope: "store/channel/{channel_id}/cart/deleted", desc: "Fires when a cart is deleted in a channel" },
  { scope: "store/channel/{channel_id}/cart/abandoned", desc: "Fires when a cart is abandoned in a channel" },
  { scope: "store/channel/{channel_id}/cart/converted", desc: "Fires when a cart is converted to an order in a channel" },
  { scope: "store/channel/{channel_id}/cart/couponApplied", desc: "Fires when a coupon is applied to a cart in a channel" },
  { scope: "store/channel/{channel_id}/cart/lineItem/*", desc: "Subscribe to all channel cart line item events" },
  { scope: "store/channel/{channel_id}/cart/lineItem/created", desc: "Fires when a line item is created in a channel cart" },
  { scope: "store/channel/{channel_id}/cart/lineItem/updated", desc: "Fires when a line item is updated in a channel cart" },
  { scope: "store/channel/{channel_id}/cart/lineItem/deleted", desc: "Fires when a line item is deleted from a channel cart" },
  { scope: "store/channel/{channel_id}/category/*", desc: "Subscribe to all channel category events" },
  { scope: "store/channel/{channel_id}/category/created", desc: "Fires when a category is created in a channel" },
  { scope: "store/channel/{channel_id}/category/updated", desc: "Fires when a category is updated in a channel" },
  { scope: "store/channel/{channel_id}/category/deleted", desc: "Fires when a category is deleted in a channel" },
  { scope: "store/channel/{channel_id}/categoryTree/updated", desc: "Fires when the category tree is updated in a channel" },
  { scope: "store/customer/channel/login/access/updated", desc: "Fires when customer channel login access is updated" },
  { scope: "store/channel/{channel_id}/settings/emailStatus/updated", desc: "Fires when channel email status settings are updated" },
  { scope: "store/channel/{channel_id}/settings/emailStatus/deleted", desc: "Fires when channel email status settings are deleted" },
  { scope: "store/channel/{channel_id}/email/templates/updated", desc: "Fires when channel email templates are updated" },
  { scope: "store/channel/{channel_id}/email/templates/deleted", desc: "Fires when channel email templates are deleted" },
  { scope: "store/channel/{channel_id}/notifications/abandonedCart/updated", desc: "Fires when channel abandoned cart notifications are updated" },
  { scope: "store/channel/{channel_id}/notifications/inventory/updated", desc: "Fires when channel inventory notifications are updated" },
  { scope: "store/channel/{channel_id}/order/*", desc: "Subscribe to all channel order events" },
  { scope: "store/channel/{channel_id}/order/created", desc: "Fires when an order is created in a channel" },
  { scope: "store/channel/{channel_id}/order/updated", desc: "Fires when an order is updated in a channel" },
  { scope: "store/channel/{channel_id}/order/archived", desc: "Fires when an order is archived in a channel" },
  { scope: "store/channel/{channel_id}/order/statusUpdated", desc: "Fires when an order status is updated in a channel" },
  { scope: "store/channel/{channel_id}/order/message/created", desc: "Fires when an order message is created in a channel" },
  { scope: "store/channel/{channel_id}/order/refund/created", desc: "Fires when an order refund is created in a channel" },
  { scope: "store/channel/{channel_id}/page/created", desc: "Fires when a page is created in a channel" },
  { scope: "store/channel/{channel_id}/page/updated", desc: "Fires when a page is updated in a channel" },
  { scope: "store/channel/{channel_id}/product/assigned", desc: "Fires when a product is assigned to a channel" },
  { scope: "store/channel/{channel_id}/product/unassigned", desc: "Fires when a product is unassigned from a channel" },
  { scope: "store/channel/{channel_id}/category/product/assigned", desc: "Fires when a product is assigned to a category in a channel" },
  { scope: "store/channel/{channel_id}/category/product/unassigned", desc: "Fires when a product is unassigned from a category in a channel" },
  { scope: "store/channel/{channel_id}/settings/route/updated", desc: "Fires when channel route settings are updated" },
  { scope: "store/channel/{channel_id}/script/created", desc: "Fires when a script is created in a channel" },
  { scope: "store/channel/{channel_id}/script/updated", desc: "Fires when a script is updated in a channel" },
  { scope: "store/channel/{channel_id}/settings/*", desc: "Subscribe to all channel settings events" },
  { scope: "store/channel/{channel_id}/settings/currency/updated", desc: "Fires when channel currency settings are updated" },
  { scope: "store/channel/{channel_id}/settings/profile/updated", desc: "Fires when channel profile settings are updated" },
  { scope: "store/channel/{channel_id}/settings/logo/updated", desc: "Fires when channel logo settings are updated" },
  { scope: "store/channel/{channel_id}/settings/logo/image/updated", desc: "Fires when channel logo image is updated" },
  { scope: "store/channel/{channel_id}/settings/favicon/image/updated", desc: "Fires when channel favicon image is updated" },
  { scope: "store/channel/{channel_id}/settings/checkout/updated", desc: "Fires when channel checkout settings are updated" },
  { scope: "store/channel/{channel_id}/settings/SEO/updated", desc: "Fires when channel SEO settings are updated" },
  { scope: "store/channel/{channel_id}/settings/robots/updated", desc: "Fires when channel robots settings are updated" },
  { scope: "store/channel/{channel_id}/settings/category/updated", desc: "Fires when channel category settings are updated" },
  { scope: "store/channel/{channel_id}/settings/product/updated", desc: "Fires when channel product settings are updated" },
  { scope: "store/channel/{channel_id}/settings/catalog/updated", desc: "Fires when channel catalog settings are updated" },
  { scope: "store/channel/{channel_id}/settings/security/updated", desc: "Fires when channel security settings are updated" },
  { scope: "store/channel/{channel_id}/settings/searchContextFilters/updated", desc: "Fires when channel search context filters are updated" },
  {
    scope: "store/channel/{channel_id}/settings/defaultCustomerGroup/updated",
    desc: "Fires when channel default customer group settings are updated",
  },
  { scope: "store/channel/{channel_id}/settings/customerPrivacy/updated", desc: "Fires when channel customer privacy settings are updated" },
  { scope: "store/channel/{channel_id}/settings/sites/updated", desc: "Fires when channel sites settings are updated" },
  { scope: "store/channel/{channel_id}/settings/sites/checkoutUrl/updated", desc: "Fires when channel checkout URL is updated" },
  { scope: "store/channel/{channel_id}/settings/sites/checkoutUrl/deleted", desc: "Fires when channel checkout URL is deleted" },
  { scope: "store/channel/{channel_id}/socialMediaLinks/updated", desc: "Fires when channel social media links are updated" },
  { scope: "store/channel/{channel_id}/theme/configuration/created", desc: "Fires when channel theme configuration is created" },
  { scope: "store/channel/{channel_id}/theme/configuration/activated", desc: "Fires when channel theme configuration is activated" },
];

// eslint-disable-next-line no-unused-vars
const PAYMENTPUTTY_PROVIDER_EXAMPLE = {
  // Controls visibility of the provider in the UI
  enabled: true,
  // The name of the provider
  name: "Example",
  // Upload logos to the `mojoactive-bigcommerce` bucket in S3 and paste the link here
  logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/example.jpg",
  // Controls which fields are required by the gift card provider
  // These field fields can be pulled from the Secrets util.
  // ex. const MerchantId = await Secrets.Store.Value(req.store,  'PaymentPutty.Example.MerchantId');
  fields: [
    {
      // this key will be saved in the secrets table following this naming convention:
      // ex. PaymentPutty.Example.MerchantId
      key: "MerchantId",
      // the label that will be displayed to the user
      label: "Custom Label",
    },
  ],
};

export const PAYMENTPUTTY_PROVIDERS = {
  // see the example above for more details
  SVS: {
    enabled: true,
    name: "SVS",
    type: "giftCard",
    desc: "A world leader in Gift Cards and Stored Value Services. SVS provides support to clients to process over 1.3 billion gift card transactions annually.",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/SVS.jpg",
    fields: [{ key: "MerchantId" }, { key: "StoreNumber" }, { key: "Division" }, { key: "Username" }, { key: "Password" }],
  },
  Shift4: {
    enabled: true,
    name: "Shift4",
    type: "giftCard",
    desc: "Shift4 is the leader in secure payment processing solutions, including point-to-point encryption, tokenization, EMV technology and point-of-sale (POS) systems.",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/Shift4.png",
    docs: "https://myportal.shift4.com/index.cfm?action=development.shift4api",
    fields: [{ key: "MerchantId" }, { key: "ClientKey" }, { key: "MerchantUrl" }],
  },
  STS: {
    enabled: true,
    name: "STS",
    type: "giftCard",
    desc: "STS develops payments acceptance software for retailers, airlines and transportation operators that maximite choice and flexibility.",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/STS.png",
    docs: "https://smarttransactionsystems.com/developers/",
    fields: [{ key: "MerchantId" }, { key: "TerminalId" }, { key: "ClientKey" }, { key: "MerchantUrl" }],
  },
  Valutec: {
    enabled: true,
    name: "Valutec",
    type: "giftCard",
    desc: "Valutec Card Solutions is a full service gift & loyalty card solutions provider.",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/Valutec.jpg",
    fields: [{ key: "TerminalId" }, { key: "ClientKey" }, { key: "MerchantUrl" }],
  },
  TSYS: {
    enabled: true,
    name: "TSYS",
    type: "giftCard",
    desc: "TSYS, a Global Payments company, is the payment stack for the future, powered by unmatched expertise",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/TSYS.png",
    docs: "https://developers.tsys.com",
    fields: [{ key: "ClientName" }, { key: "ClientSite" }, { key: "ClientKey" }, { key: "MerchantUrl" }],
  },
  Voucherify: {
    enabled: true,
    name: "Voucherify",
    type: "giftCard",
    desc: "An affordable, API-first promotion software that requires minimum effort to integrate and offers plenty of features",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/Voucherify.png",
    docs: "https://docs.voucherify.io/docs/authentication",
    fields: [{ key: "MerchantId" }, { key: "ClientKey" }, { key: "MerchantUrl" }],
  },
  GiveX: {
    enabled: true,
    name: "GiveX",
    type: "giftCard",
    desc: "Givex provides the restaurant, retail, and QSR industries with cutting-edge pos (epos) solutions, management systems, gift cards, loyalty programs.",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/GiveX.jpg",
    fields: [{ key: "Host" }, { key: "HostPort" }, { key: "TimeoutMS" }, { key: "UserName" }, { key: "Password" }, { key: "PreAuthOnly" }],
  },
  SmarterCommerce: {
    enabled: true,
    name: "SmarterCommerce",
    type: "creditCard",
    desc: "SmarterCommerce is the leader in unified commerce for JD Edwards including Credit Card Processing, Ecommerce, Point of Sale, and Call Center solutions",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/SmarterCommerce.png",
    docs: "https://www.smartercommerce.net/product-guides",
    fields: [{ key: "ServiceName" }, { key: "AccessToken" }, { key: "ConnectionName" }, { key: "Owner" }, { key: "URL" }],
  },
  Bambora: {
    enabled: true,
    name: "Bambora",
    label: "TD Online Mart by Bambora",
    type: "creditCard",
    desc: "TD Merchant Solutions offers credit card readers and Point of Sale (POS) systems that have security features and mobility options that support how you",
    logo: [
      "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/TdMerchant.jpg",
      "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/bambora.png",
    ],
    docs: "https://dev.na.bambora.com/docs/guides/",
    fields: [{ key: "MerchantId" }, { key: "Passcode" }, { key: "ReportingPasscode" }, { key: "APIHash" }],
  },
};

export const PAYMENTPUTTY_WEBHOOK_EVENTS = [
  "payment-putty/v2/apply",
  "payment-putty/v2/check",
  "payment-putty/v2/commit",
  "payment-putty/v2/establish",
  "payment-putty/v2/finalize",
  "payment-putty/v2/remove",
  "payment-putty/v2/verify",
  "payment-putty/v2/authorize-and-capture",
  "payment-putty/v2/refund",
  "payment-putty/v2/void",
];

export const PAYMENTPUTTY_WEBHOOK_DESCRIPTIONS = [
  { scope: "payment-putty/v2/apply", desc: "Not currently used." },
  { scope: "payment-putty/v2/check", desc: "Step 1 in normal flow." },
  { scope: "payment-putty/v2/commit", desc: "Step 2 in normal flow." },
  { scope: "payment-putty/v2/establish", desc: "Used for clients that require check balance outside of the cart" },
  { scope: "payment-putty/v2/finalize", desc: "Step 3 in normal flow." },
  { scope: "payment-putty/v2/remove", desc: "Not currently used." },
  { scope: "payment-putty/v2/verify", desc: "TODO - Look into how this is currently used." },
  { scope: "payment-putty/v2/authorize-and-capture", desc: "Authorize And Capture Credit Card" },
  { scope: "payment-putty/v2/refund", desc: "Return an amount back to the credit card" },
  { scope: "payment-putty/v2/void", desc: "Cancels that transaction" },
];

export const PAYMENTPUTTY_WEBHOOK_PAYLOADS = [
  { scope: "payment-putty/v2/apply", payload: JSON.stringify(new ApplyTransactionDTO(), null, 2) },
  { scope: "payment-putty/v2/check", payload: JSON.stringify(new CheckBalanceTransactionDTO(), null, 2) },
  { scope: "payment-putty/v2/commit", payload: JSON.stringify(new CommitTransactionDTO(), null, 2) },
  { scope: "payment-putty/v2/establish", payload: {} },
  { scope: "payment-putty/v2/finalize", payload: JSON.stringify(new CommitTransactionDTO(), null, 2) },
  { scope: "payment-putty/v2/remove", payload: JSON.stringify(new RemoveRequestDTO(), null, 2) },
  { scope: "payment-putty/v2/verify", payload: JSON.stringify(new VerifyTransactionDTO(), null, 2) },
  { scope: "payment-putty/v2/authorize-and-capture", payload: JSON.stringify({}, null, 2) },
  { scope: "payment-putty/v2/refund", payload: JSON.stringify({}, null, 2) },
  { scope: "payment-putty/v2/void", payload: JSON.stringify({}, null, 2) },
];

// You can use https://brandfetch.com/ to get the logo and image for your integration
// Then use https://www.figma.com/file/Z7Yz6VvDsP1vy2k9J4zmzh/Integration-Brand-Assets?node-id=0%3A1&t=KfEXM45g4KmPphKH-1 to export at correct dimensions
export const INTEGRATIONS = {
  ShipStation: {
    enabled: true,
    name: "ShipStation",
    desc: "ShipStation is a web-based shipping solution that helps you get orders out the door quickly and easily.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/shipstation.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/shipstation-icon.png",
    docs: "https://www.shipstation.com/docs/api/",
    url: "https://www.shipstation.com/",
    fields: [{ key: "API_KEY" }, { key: "API_SECRET" }, { key: "PARTNER_KEY" }],
  },
  Klaviyo: {
    enabled: true,
    name: "Klaviyo",
    desc: "Klaviyo is a marketing automation platform that helps you collect, analyze, and act on your customer data.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/klaviyo.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/klaviyo-icon.png",
    docs: "https://developers.klaviyo.com/en/reference/api_overview",
    url: "https://www.klaviyo.com/",
    fields: [{ key: "API_KEY" }],
  },
  TaxJar: {
    enabled: true,
    name: "TaxJar",
    desc: "TaxJar is a sales tax automation platform that helps merchants calculate sales tax, file sales tax returns, and manage exemption certificates.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/taxjar.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/taxjar-icon.png",
    docs: "https://developers.taxjar.com/api/reference/",
    url: "https://www.taxjar.com/",
    fields: [{ key: "API_KEY" }],
  },
  AmazonSeller: {
    enabled: true,
    name: "AmazonSeller",
    desc: "Amazon's Seller Central is a platform that helps you sell your products on Amazon.com.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/amazon-seller.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/amazon-seller-icon.png",
    docs: "https://developer.amazonservices.com/",
    url: "https://sellercentral.amazon.com/",
    fields: [
      { key: "SELLER_ID" },
      { key: "MARKETPLACE_ID" },
      { key: "OAUTH_ACCESS_TOKEN" },
      { key: "OAUTH_REFRESH_TOKEN" },
      { key: "API_KEY" },
      { key: "API_SECRET" },
    ],
  },
  USPS: {
    enabled: true,
    name: "USPS",
    desc: "Access USPS API's to calculate rates, generate labels, track packages and validate addresses.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/usps.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/usps-icon.png",
    docs: "https://www.usps.com/business/web-tools-apis/welcome.htm",
    url: "https://www.usps.com/",
    fields: [{ key: "USER_ID" }, { key: "PASSWORD" }],
  },
  FedEx: {
    enabled: true,
    name: "FedEx",
    desc: "Integrate FedEx API's to access real-time rates, create shipments, schedule pickups, track packages and manage your shipping operations.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/fedex.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/fedex-icon.png",
    docs: "https://developer.fedex.com/",
    url: "https://fedex.com/",
    fields: [{ key: "API_KEY" }, { key: "SECRET_KEY" }, { key: "SHIPPING_ACCOUNT" }],
  },
  GoDataFeed: {
    enabled: true,
    name: "GoDataFeed",
    desc: "GoDataFeed is a product feed management platform that helps you list your products on shopping channels.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/godatafeed.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/godatafeed-icon.png",
    docs: "https://developer.godatafeed.com/#introduction",
    url: "https://www.godatafeed.com/",
    fields: [{ key: "CONSUMER_KEY" }],
  },
  Braintree: {
    enabled: true,
    name: "Braintree",
    desc: "Access Braintree's API's to process payments from PayPal, Venmo, credit and debit cards, Apple Pay.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/braintree.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/braintree-icon.png",
    docs: "https://developer.paypal.com/braintree/docs/start/hello-server/node",
    url: "https://developer.paypal.com/braintree/docs",
    fields: [{ key: "ENVIRONMENT" }, { key: "MERCHANT_ID" }, { key: "PUBLIC_KEY" }, { key: "PRIVATE_KEY" }, { key: "CLIENT_AUTHORIZATION" }],
  },
  B2B: {
    enabled: true,
    name: "B2B",
    desc: "B2B is a platform that helps you manage your wholesale customers.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/bundle-b2b.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/bundle-b2b-icon.png",
    docs: "https://bundleb2b.stoplight.io/docs/openapi/quick-start",
    url: "https://www.bigcommerce.com/apps/b2b-edition/",
    fields: [{ key: "API_KEY" }, { key: "API_KEY_SECONDARY" }],
  },
  Zinrelo: {
    enabled: true,
    name: "Zinrelo",
    desc: "Zinrelo is an enterprise-grade, loyalty rewards platform that maximizes repeat sales & per-customer revenue through 360-degree customer engagement",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/zinrelo.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/zinrelo-icon.png",
    docs: "https://zinrelo.github.io/slate/#overview",
    url: "https://www.zinrelo.com/",
    fields: [{ key: "API_KEY" }, { key: "PARTNER_ID" }],
  },
  Salsify: {
    enabled: true,
    name: "Salsify",
    desc: "Salsify provides the industry's only unified PXM platform that was purpose-built to help you drive growth, improve efficiency, and ultimately achieve digital...",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/salsify.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/salsify-icon.png",
    docs: "https://developers.salsify.com/reference",
    url: "https://developers.salsify.com/reference",
    fields: [{ key: "Token" }, { key: "OrgId" }, { key: "URL" }],
  },
  Yotpo: {
    enabled: true,
    name: "Yotpo",
    desc: "Yotpo is a marketing platform that helps you collect, analyze, and act on your customer data.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/yotpo.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/yotpo-icon.png",
    docs: "https://apidocs.yotpo.com/reference",
    url: "https://www.yotpo.com/",
    fields: [{ key: "APP_KEY" }],
  },
  UPS: {
    enabled: true,
    name: "UPS",
    desc: "Access UPS API's to calculate rates, generate labels, track packages and validate addresses.",
    image: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/ups.png",
    logo: "https://mojoactive-bigcommerce.s3.us-east-1.amazonaws.com/images/ups-icon.png",
    docs: "https://developer.ups.com/api/reference",
    url: "https://developer.ups.com/",
    fields: [{ key: "UPSClientId" }, { key: "UPSClientSecret" }, { key: "UPSAccountOauth" }],
  },
};
