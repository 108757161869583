import { DataProvider as GeneratedDataProvider, ResponseError, ResponseOk, Request, RequestThrottle, setConfig } from "@mojoactive/bc-api";

const config = {
  PAGINATION_CONCURRENCY: 25,
  REQUEST_QUEUE_CONCURRENCY: 25,
  MAX_RETRIES: 5,
};

if (typeof window === "undefined") {
  const { EventSpan } = require("../core/lib/tracing");
  setConfig({ EventSpan, ...config });
} else {
  setConfig({ ...config });
}

const DataProvider = {
  ...GeneratedDataProvider,
  Promotions: {
    GetAll: async (ctx) => Request(ctx, "GET", "/v3/promotions"),
    Update: async (ctx, id, body) => Request(ctx, "PUT", `/v3/promotions/${id}`, body),
  },
  Segments: {
    GetAll: async (ctx, query) => Request(ctx, "GET", `/v3/segments` + (query ? `${query}` : "")),
    Create: async (ctx, body) => Request(ctx, "POST", "/v3/segments", body),
    Update: async (ctx, body) => Request(ctx, "PUT", `/v3/segments`, body),
    Delete: async (ctx, query) => Request(ctx, "DELETE", `/v3/segments` + (query ? `${query}` : "")),
  },
  ShopperProfiles: {
    GetAll: async (ctx) => Request(ctx, "GET", `/v3/shopper-profiles`),
    Create: async (ctx, body) => Request(ctx, "POST", `/v3/shopper-profiles`, body),
    Delete: async (ctx, query) => Request(ctx, "DELETE", `/v3/shopper-profiles` + (query ? `${query}` : "")),
  },
  ShopperProfileSegments: {
    GetAll: async (ctx, id) => Request(ctx, "GET", `/v3/segments/${id}/shopper-profiles`),
    Create: async (ctx, id, body) => Request(ctx, "POST", `/v3/segments/${id}/shopper-profiles`, body),
    Delete: async (ctx, id, query) => Request(ctx, "DELETE", `/v3/segments/${id}/shopper-profiles` + (query ? `${query}` : "")),
  },
};

export { DataProvider, Request, RequestThrottle, ResponseError, ResponseOk };
